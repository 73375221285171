import React, {useState, useEffect} from "react"
import { normalizeTwoWords, findTarget, findRel } from "../../../shared/utils/stringUtilities"
import './navAccordion.scss'
import { generateSlug } from "../../../utils/generateSlug";

const NavAccordion = (props: NavAccordionInterface) => {

	const {
		category,
		index,
		setActiveItem,
		activeItem,
		isAnyActive,
		setIsAnyActive
	} = props;

	const [isActive, toggleActive] = useState(false);

	useEffect(()=>{ 
		index !== activeItem && toggleActive(false)
   }, [activeItem])
   
   const listaMenu = ( subcat:any, k:number, slug:string ) => (
      <li  className= { `gtmMenuTransSubcategoria${normalizeTwoWords(subcat.nombreSubcategoria)}`} key={ `cat${k}` }> 
         <a className= { `gtmMenuTransSubcategoria${normalizeTwoWords(subcat.nombreSubcategoria)}` }
            target={ findTarget(slug) }
            rel={findRel(slug)}
            href={slug}> { subcat.nombreSubcategoria } </a>
      </li>
   )

   const existCategory = category.referenciaSubcategorias && category.referenciaSubcategorias.length > 0;

	return (
			
		<>
			{ 
				category.referenciaSubcategorias ? (
               <button
                  id={`${
                     !isActive || (!isAnyActive && index !== activeItem)
                         ? `gtm_click_menu_comfama_home_header_${generateSlug(
                           category.nombreCategoria.split(' ')[0],
                           '_',
                           )}`
                        : ''
                  }`}
                  className={`a-nav-accord__btn ${
                     isActive ? 'a-nav-accord__btn--active' : ''
                  } ${
                     !isAnyActive && index !== activeItem
                        ? 'a-nav-accord__btn--locked'
                        : ''
                  }`}
                  onClick={() => {
                     setActiveItem(index);
                     toggleActive(!isActive);
                     setIsAnyActive(isActive);
                  }}
                  aria-label={category.nombreCategoria}
               >
                  {category.nombreCategoria}
               </button>
				):(
					<a href={`/${category.slug}`}
                  id={`gtm_click_menu_comfama_home_header_${generateSlug(category.nombreCategoria.split(' ')[0], '_')}`}
						className={`a-nav-accord__link ${!isAnyActive && index!==activeItem?'a-nav-accord__link--locked':''}`}
               > 
						{category.nombreCategoria} 
					</a>
				)
			}
			<ul className={ 
				`a-nav-accord__sub ${isActive && index === activeItem? 'a-nav-accord__sub--active':''}`
				}>
				<li> Encontrarás </li>
            {existCategory &&
               listaMenu(category.referenciaSubcategorias[0], -1, category.referenciaSubcategorias[0].link || `/${category.referenciaSubcategorias[0].slug}`)
            }
            { existCategory && category.referenciaSubcategorias[0].referenciaSubcategorias && 
               category.referenciaSubcategorias[0].referenciaSubcategorias.map((subcat, k) => {
                  return subcat.verEnMenu?listaMenu(subcat,k,subcat.link||`/${category.referenciaSubcategorias[0].slug}${subcat.slug}`):null;
               })
            }
			</ul>
		</>
	)
}

interface NavAccordionInterface {
	category: any,
	index: number,
	setActiveItem: Function,
	activeItem: number,
	isAnyActive: boolean,
	setIsAnyActive: Function
}

export default NavAccordion
